import { AxiosResponse } from "axios";
import { APIService } from "common";
import { put } from "redux-saga/effects";
import { setTransactionlist } from "..";

export function* getTransactionListSaga(): any {
    try {

        const response: AxiosResponse = yield APIService.Validation.GetTransactionlist.call();

        if (response?.data) {
            yield put(setTransactionlist(response.data));
        }

    } catch (error) {
        console.log(error);
    }
}