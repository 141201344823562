import { ChartHelper } from "common/helpers";
import { Dictionary } from "common/entities";
import { isNullOrUndefined } from "common/Utils";
import _uniqueId from "lodash/uniqueId";
import { useEffect, useRef, useState } from "react";
import { IBaseChartProps } from "../entities";
import { createChart, IChartOptions } from "./createChart";

export default function DonutChart({ data, chartOptions, ...props }: IGaugeChartProps) {
    const chartHelperRef = useRef<ChartHelper.PieChart>();
    const [chartID, setChartID] = useState(_uniqueId(props.chartID));

    useEffect(() => {
        setChartID(_uniqueId(props.chartID));
    }, [props.chartID, chartOptions?.lightTheme]);

    function setData() {
        if (isNullOrUndefined(data)) {
            data = [];
        }

        if (chartHelperRef.current) {
            chartHelperRef.current.setData(data);

            const centerLabel = chartOptions?.centerLabel;

            if (centerLabel?.enabled) {

                if (centerLabel?.title) {
                    chartHelperRef.current.centerLabel?.set("text", centerLabel.title);
                } else {
                    chartHelperRef.current.centerLabel?.set("text", `[font-size:16px]${centerLabel?.text} 
                \n [font-size:22px bold]${data.reduce((accu, item) => accu + item.value, 0)}[/]`);
                }
            }
        }
    }

    useEffect(() => {
        setData();
    }, [data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(chartID, chartOptions);
            setData();
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);

    useEffect(() => {
        chartHelperRef.current?.root?.dispose();

        chartHelperRef.current = createChart(chartID, chartOptions);
        setData();

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, [chartOptions?.lightTheme]);

    return (
        <>
            <div className={props.className} style={Object.assign({ height: props.height, width: props.width }, props.style)} id={chartID} />
        </>
    );
}



export interface IGaugeChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    height?: number;
    width?: number;
    chartOptions?: IChartOptions;
}