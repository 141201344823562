import { useChangeImage, useViewerImageList } from 'components/GDicomViewer';
import { useSelectSelectedImage } from './useSelectSelectedImage';

export function useChangeImageIndex() {
    const imageList = useViewerImageList();
    const imageListLength = imageList?.length;
    const image = useSelectSelectedImage();
    const changeImage = useChangeImage();
    const imageIndex = imageList?.findIndex(_image => _image?.imageId === image?.imageId);

    function changeImageIndex(step: 1 | -1) {
        changeImage({ imageIndex: imageIndex + step });
    }

    return { imageList, imageListLength, imageIndex, changeImageIndex };
}
