import { AxiosRequestConfig } from 'axios';
import { ServiceEnum } from 'common/enum';
import { _compact, _uuid, getDeviceId, getSessionId } from 'common/Utils';

export const injectHeaders = (config: AxiosRequestConfig): AxiosRequestConfig => {
    try {
        const token = sessionStorage.getItem('accessToken');

        if (token != null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (!config.headers.activity) config.headers['activity'] = 'user';

        config.headers['x-session-id'] = getSessionId();

        config.headers['x-device-id'] = getDeviceId();

        if (!config.headers['x-request-id']) config.headers['x-request-id'] = _uuid();

        return config;
    } catch (error) {
        throw new Error(JSON.stringify(error));
    }
};

export const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Credentials': '*',
    'X-Requested-With': 'XMLHttpRequest',
};

export function getBaseConfig(service?: ServiceEnum) {
    return {
        baseURL: getUri(service),
        baseHeaders,
    };
}

export function getUri(service?: ServiceEnum, path?: string | Array<string>) {
    if (Array.isArray(path)) {
        path = path.join('/');
    }
    return _compact([`/${service || ''}`, path]).join('/');
}
