import cx from 'classnames';
import { _isEmpty } from 'common/Utils';
import React from 'react';
import DetailsMenu, { IDetailsMenuProps } from '../DetailsMenu';
import Styles from './Styles.module.scss';

export default function CardInfo({ detailsMenuProps, accessStatus, extraTags, caption, description, source }: IInfoSectionProps) {
    return (
        <div className={Styles.CardInfoContainer}>
            <div className={Styles.CardInfoHeader}>
                {caption}
                {(Boolean(accessStatus) || !_isEmpty(detailsMenuProps?.menu)) && (
                    <div className={Styles.CardInfoHeaderMenu}>
                        {Boolean(accessStatus) && (
                            <div
                                className={cx(Styles.Access, 'caption2-bold')}
                                style={{ backgroundColor: accessPalette[accessStatus] }}
                            >
                                {accessStatus}
                            </div>
                        )}

                        {extraTags}

                        {!_isEmpty(detailsMenuProps?.menu) && (
                            <div className={Styles.DetailsMenu}>
                                <DetailsMenu {...detailsMenuProps} />
                            </div>
                        )}
                    </div>
                )}
            </div>

            {source}
            <div className={Styles.Descripton}>{description}</div>
        </div>
    );
}

const accessPalette = { public: '#00ffa7', private: '#503795' };

export interface IInfoSectionProps {
    caption: React.ReactNode;
    description: React.ReactNode;
    accessStatus?: 'public' | 'private';
    extraTags?: Array<React.ReactNode>;
    detailsMenuProps?: IDetailsMenuProps;
    source?: React.ReactNode;
}
