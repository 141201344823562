import { IClassMappingSelection } from 'pages/AnnotationCS/entities';

export interface PreAnnotationConfig {
    classMappingConfig?: Array<IClassMappingSelection>;
    modelId?: string;
    preAnnotationMode?: PreAnnotationMode;
    modalOpen?: boolean;
    validation?: string;
    labelingConfig?: string;
    readers?: string[];
}

export type PreAnnotationMode = 'assisted-slice-selection' | 'prediction';

export function PreAnnotationConfigFactory(): PreAnnotationConfig {
    return {
        classMappingConfig: [],
        modelId: null,
        preAnnotationMode: 'prediction',
        modalOpen: false,
        validation: null,
        labelingConfig: null,
        readers: [],
    };
}
