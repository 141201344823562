import { IWindowLevel, isWindowLevelExist, isWindowLevelsEqual } from 'common/enum';
import { cornerstone } from '../CornerstoneInitHelper/CornerstoneHelper';
import { AnnotationManagement } from './AnnotationManagement';

export * from './AnnotationAdapter';
export * from './interface';

export class DicomViewerHelper extends AnnotationManagement {
    windowLevel: IWindowLevel;

    constructor() {
        super();

        console.log(this);

        this.setWindowLevel = this.setWindowLevel.bind(this);
        this.getWindowLevel = this.getWindowLevel.bind(this);
        this.getDefaultWindowLevel = this.getDefaultWindowLevel.bind(this);
        this.applyElementWaitedMethods = this.applyElementWaitedMethods.bind(this);
    }

    setWindowLevel(windowLevel: IWindowLevel, index?: number) {
        const element = this.getViewportElement(index);
        if (!windowLevel || !isWindowLevelExist(windowLevel) || !element || !this.isElementEnabled(index)) return;
        let viewport = cornerstone?.getViewport(element);
        if (!viewport || isWindowLevelsEqual(windowLevel, this.getWindowLevel(index))) return;
        viewport.voi.windowWidth = windowLevel.ww;
        viewport.voi.windowCenter = windowLevel.wc;
        cornerstone?.setViewport(element, viewport);

        this.windowLevel = windowLevel;
    }

    getWindowLevel(index?: number) {
        const element = this.getViewportElement(index);
        if (!element) return;
        let viewport = cornerstone?.getViewport(element);
        if (!viewport) return;
        return {
            ww: Math.round(viewport.voi.windowWidth),
            wc: Math.round(viewport.voi.windowCenter),
        };
    }

    getDefaultWindowLevel(index?: number) {
        const image = this.getViewportImage(index);
        if (!image) return;
        return {
            ww: image.windowWidth,
            wc: image.windowCenter,
        };
    }

    rotateImage(degree: number, index: number = this.activeElementIndex) {
        const viewport = cornerstone.getViewport(this.elementMap.get(index));
        viewport.rotation += degree;
        cornerstone.setViewport(this.elementMap.get(index), viewport);
    }

    flipImage(axis: string, index: number = this.activeElementIndex) {
        const viewport = cornerstone.getViewport(this.elementMap.get(index));
        if (axis === 'horizontal') {
            viewport.hflip = !viewport.hflip;
        } else {
            viewport.vflip = !viewport.vflip;
        }
        cornerstone.setViewport(this.elementMap.get(index), viewport);
    }

    invertImage() {
        const viewport = cornerstone.getViewport(this.elementMap.get(this.activeElementIndex));
        viewport.invert = !viewport.invert;
        cornerstone.setViewport(this.elementMap.get(this.activeElementIndex), viewport);
    }

    fitToWindow() {
        cornerstone.fitToWindow(this.elementMap.get(this.activeElementIndex));
    }
}
