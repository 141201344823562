import type { IDataset } from 'common';
import constate from 'constate';
import { useDicomViewerHelperInstance } from './useDicomViewerHelperInstance';
import { useViewportElementMapState } from './useViewportElementMapState';

function useDicomViewerState({ setIsLoaded, dataset }: IProps) {
    const { dicomViewerHelper } = useDicomViewerHelperInstance(setIsLoaded, dataset);
    const viewportElementMapState = useViewportElementMapState();

    return {
        dicomViewerHelper,
        viewportElementMapState,
    };
}

export const [DicomViewerStateProvider, useDicomViewerInstance, useViewportElementMap] = constate(
    useDicomViewerState,
    value => value.dicomViewerHelper,
    value => value.viewportElementMapState
);

interface IProps {
    setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    dataset: IDataset;
}
