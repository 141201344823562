import { APISlice } from 'app/store/APISlice';
import { ServiceEnum } from 'common/enum';
import { IMetadataEl, IMetadataField, IMetadataTracking } from '../entities/IMetadata';

export const MetadataService = APISlice.injectEndpoints({
    endpoints: builder => ({
        getMetadata: builder.query<IMetadataTracking, string>({
            query: metadata_id => ({
                url: `${ServiceEnum.Api}/Metadata/GetMetadata`,
                params: { metadata_id },
            }),
        }),
        getMetadataList: builder.query<Array<IMetadataTracking>, string | undefined>({
            query: dataset_id => ({
                url: `${ServiceEnum.Api}/Metadata/GetMetadataList`,
                params: { dataset_id },
            }),
            providesTags: (result, error, dataset_id) =>
                dataset_id ? [{ type: 'MetadataList', id: dataset_id }, 'MetadataList'] : ['MetadataList'],
        }),
        removeMetadata: builder.mutation<void, { dataset_id: string; metadata_id: string }>({
            query: params => ({
                url: `${ServiceEnum.Api}/Metadata/RemoveMetadata`,
                params: params,
            }),
            invalidatesTags: (result, error, args) => ['MetadataList', { type: 'Dataset', id: args?.dataset_id }],
        }),
        createMetadata: builder.mutation<IMetadataTracking, ICreateMetadataArgs>({
            query: args => ({
                url: `${ServiceEnum.Api}/Metadata/CreateMetadata`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: response => ['MetadataList', { type: 'Dataset', id: response?.dataset_id }],
        }),
        getSnomedTags: builder.query<Array<string>, { dataset_id: string; study_id: string }>({
            query: params => ({
                url: `${ServiceEnum.Api}/Metadata/GetSnomedTags`,
                params: params,
            }),
        }),
    }),
});

export const {
    useGetMetadataQuery,
    useGetMetadataListQuery,
    useRemoveMetadataMutation,
    useCreateMetadataMutation,
    useGetSnomedTagsQuery,
} = MetadataService;

export interface ICreateMetadataArgs {
    dataset_id: string;
    metadata_name: string;
    metadata_data: Array<IMetadataEl>;
    metadata_fields: Array<IMetadataField>;
}
