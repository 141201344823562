import { ConfigProvider, Table } from 'antd';
import cs from 'classnames';
import { Dictionary } from 'common';
import GNothingToSee from 'components/GNothingToSee';
import Styles from './Styles.module.scss';
import { IDataGridProps } from './helpers/interface';

export function GDataGrid<T extends Dictionary>({ className, page, pagination, tableClassName, ...props }: IDataGridProps<T>) {
    return (
        <div className={cs(Styles.DataGridContainer, className, { [Styles.Active]: page === 1 })}>
            <ConfigProvider renderEmpty={() => <GNothingToSee />}>
                <Table
                    pagination={pagination ?? { showSizeChanger: false }}
                    className={tableClassName}
                    {...props}
                />
            </ConfigProvider>
        </div>
    );
}

export * from './GTableCell';
export * from './helpers/getFilters';
export * from './helpers/interface';
