import { CategoryAxis } from "@amcharts/amcharts5/xy";
import { Dictionary } from "common";
import { ChartHelper } from "common/";
import _isEqual from "lodash/isEqual";
import { useEffect, useRef } from "react";
import { useUpdateEffect } from "react-use";
import { IBaseChartProps } from "../entities";
import { createChart, IChartOptions } from "./createChart";
import { makeSeries } from "./makeSeries";
import * as am5 from "@amcharts/amcharts5";

export default function MultiSeriesBarChart({ chartID, data, categoryList, locationX, lightTheme, ...props }: IBarChartProps) {
    const chartHelperRef = useRef<ChartHelper.XYChart>();
    const categoryRef = useRef<Array<string>>([]);

    function setSeries() {
        if (!_isEqual(categoryList, categoryRef.current)) {
            while (chartHelperRef.current.series?.length > 0) {
                chartHelperRef.current.series.pop().dispose();
            }

            chartHelperRef.current.data = data;
            categoryRef.current = categoryList;
            categoryList?.forEach(name =>
                makeSeries(
                    chartHelperRef.current,
                    name,
                    chartID,
                    locationX,
                    lightTheme,
                    props?.chartOptions?.borderRadius,
                    props?.chartOptions?.barWidth,
                    props?.chartOptions?.maskBullets
                )
            );
        }
        chartHelperRef.current.setData(data);
    }

    useUpdateEffect(() => {
        (chartHelperRef.current?.xAxis as CategoryAxis<any>)
            .get('renderer')
            .labels.template.set('maxWidth', props.chartOptions?.xAxis?.wrapLabels ? 60 : 150);

        categoryRef.current = categoryList;
    }, [props.chartOptions?.xAxis?.wrapLabels]);

    useUpdateEffect(() => {
        (chartHelperRef.current?.xAxis as any)
            .get('renderer')
            .labels.template.set('fill', props?.chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'));
        (chartHelperRef.current?.yAxis as any)
            .get('renderer')
            .labels.template.set('fill', props?.chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'));
    }, [props?.chartOptions?.lightTheme]);

    useEffect(() => {
        if (data && chartHelperRef.current) {
            setSeries();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, categoryList, lightTheme, props?.chartOptions?.borderRadius]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(chartID, props.chartOptions);

            if (data) {
                setSeries();
                chartHelperRef.current.chart.appear(1000, 100);
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        chartHelperRef.current?.root?.dispose();
        chartHelperRef.current = createChart(chartID, props.chartOptions);

        if (data) {
            setSeries();
            while (chartHelperRef.current.series?.length > 0) {
                chartHelperRef.current.series.pop().dispose();
            }

            chartHelperRef.current.data = data;
            categoryRef.current = categoryList;
            categoryList?.forEach(name =>
                makeSeries(
                    chartHelperRef.current,
                    name,
                    chartID,
                    locationX,
                    lightTheme,
                    props?.chartOptions?.borderRadius,
                    props?.chartOptions?.barWidth,
                    props?.chartOptions?.maskBullets
                )
            );
            chartHelperRef.current.setData(data);
            chartHelperRef.current.chart.appear(1000, 100);
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        props?.chartOptions?.lightTheme,
    ]);

    return (
        <>
            <div
                className={props.className}
                style={{ height: props.height, width: props.width }}
                id={chartID}
            />
        </>
    );
}

export interface IBarChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    categoryList?: Array<string>;
    chartOptions?: IChartOptions;
    getChartHelperRef?: (chartHelperRef: ChartHelper.XYChart) => void;
    locationX?: number;
    lightTheme?: boolean;
}