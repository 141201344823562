import { useUpdateSearchParams } from 'common/hooks';
import { useActiveViewportDetails, useChangeImage } from 'components/GDicomViewer';
import { useChangeImageIndex } from 'pages/AnnotationCS/views/ToolView/hooks/useChangeImageIndex';
import { selectShowDicomViewer } from 'pages/Playground/store/selectors';
import { useSelector } from 'react-redux';

export function useOnWheel() {
    const showDicomViewer = useSelector(selectShowDicomViewer);
    const updateSearchParams = useUpdateSearchParams();
    const activeViewportState = useActiveViewportDetails();
    const changeImage = useChangeImage();
    const { imageIndex } = useChangeImageIndex();

    return function onWheel(e: React.WheelEvent<HTMLImageElement>) {
        if (showDicomViewer || e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) return;
        const newIndex = imageIndex + Math.sign(e.deltaY);
        if (newIndex < 0 || newIndex >= activeViewportState.imageList?.length) return;
        const image = activeViewportState.imageList?.[newIndex]?.imageId;
        updateSearchParams({ image });
        changeImage({ imageId: image });
    };
}
