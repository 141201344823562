import { LineSvgProps, ResponsiveLine, SliceTooltip } from '@nivo/line';
import { IBaseChartProps } from '../entities';
import { useSerieLegend } from '../hooks';
import { ISerie } from '../hooks/useSerieLegend';
import { nivoTheme, nivoThemeLight } from '../nivoTheme';

export default function LineChart({ data, chartOptions, ...props }: ILineChartProps) {
    const {lightTheme} = chartOptions
    const { renderSerieLegends, selectedSeries } = useSerieLegend({data, lightTheme});

    function getChartData() {
        return data?.filter(item => selectedSeries?.includes(item.id));
    }

    const ChartProps: LineSvgProps = {
        data: getChartData(),
        margin: { top: 20, right: 20, bottom: 50, left: 60 },
        xScale: { type: 'linear', min: chartOptions?.xAxis?.min, max: chartOptions?.xAxis?.max },
        yScale: { type: 'linear', min: chartOptions?.yAxis?.min, max: chartOptions?.yAxis?.max },
        yFormat: " >-.1f",
        curve: "linear",
        colors: d => d.color,
        enablePoints: false,
        useMesh: true,
        theme: lightTheme ? nivoThemeLight : nivoTheme,
        lineWidth: 2,
        axisTop: null,
        axisBottom: {
            // format: '.1f',
            legend: chartOptions?.xAxis?.title,
            legendOffset: 36,
            legendPosition: 'middle'
        },
        axisLeft: {
            // format: '.1f',
            legend: chartOptions?.yAxis?.title,
            legendOffset: -40,
            legendPosition: 'middle'
        },
        sliceTooltip: chartOptions?.sliceTooltip,
        enableSlices: chartOptions?.sliceTooltip ? "x" : undefined
    };

    if (chartOptions?.enableArea) {
        ChartProps.enableArea = true;
        ChartProps.areaBlendMode = "difference";
        ChartProps.areaOpacity = 0.15;
    }

    return (
        <>
            <div id={props.chartID} className={props.className} >
                {renderSerieLegends()}
                <ResponsiveLine {...ChartProps} />
            </div>
        </>
    );
}

interface ILineChartProps extends IBaseChartProps {
    data?: Array<ISerie>;
    chartOptions?: IChartOptions;
}


export interface IChartOptions {
    xAxis?: {
        title?: string;
        min?: number,
        max?: number;
    };
    yAxis?: {
        title?: string;
        min?: number,
        max?: number;
    };
    enableArea?: boolean;
    sliceTooltip?: SliceTooltip;
    lightTheme?: boolean;
}