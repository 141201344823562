import { Dictionary } from "common";
import { RootState } from "redux/store";
import { createSelector } from "reselect";
import toJsonSchema from "to-json-schema";

export const selectModelArtifactProps = (state: RootState) => state.models.modelUpladState.modelArtifactProps;
export const selectModelFiles = (state: RootState) => state.models.modelUpladState.modelFiles;
export const selectDeploymentModalState = (state: RootState) => state.models.common.deploymentModalState;
export const selectIsDrawerVisible = (state: RootState) => state.models.common.isDrawerVisible;
export const selectModelListLoading = (state: RootState) => state.models.common.modelListLoading;
export const selectModelOutputExampleSchema = (state: RootState) => state.models.common.modelOutputExampleSchema;
export const selectModelFilters = (state: RootState) => state.models.common.modelFilters;
export const selectModelSorting = (state: RootState) => state.models.common.modelSorting;
export const selectModelStopLoading = (state: RootState) => state.models.common.modelStopLoading;

export const selectTrainingItem = (state: RootState) => state.models.modelTrainingState.selectedModelTrainingItem;
// export const selectBackbone = (state: RootState) => state.models.modelTrainingState.modelTrainingParams?.backbone;
export const selectModel = (state: RootState) => state.models.modelTrainingState.modelTrainingParams?.model;

export const selectConfigId = (state: RootState) => state.models.modelTrainingState.modelTrainingParams?.configId;

export const selectConfigParams = (state: RootState) => state.models.modelTrainingState.modelTrainingParams;

export const selectIsEditModalOpen = (state:RootState) => state.models.modelTrainingState.isEditModalOpen
export const selectIsCustomTrainingModalOpen = (state: RootState) => state.models.modelTrainingState.isCustomTrainingModalOpen;
export const selectActiveView = (state: RootState) => state.models.modelTrainingState.activeView;
export const selectConfigList = (state: RootState) => state.models.modelTrainingState.selectedConfigList;
export const selectActiveTabKey = (state: RootState) => state.models.modelTrainingState.activeTabKey;
export const selectModelFormParams = (state: RootState) => state.models.modelTrainingState.modelFormParams;

export function getOutputExampleSchemaSelector(modelId: string) {
    return createSelector<any, toJsonSchema.JSONSchema3or4>(
        selectModelOutputExampleSchema,
        (outputExampleMap: Dictionary<any>) => outputExampleMap?.[modelId]
    );
}

export const selectPredictionList = (state: RootState) => state.models.predictionExplorerState.selectedPredictionList;
export const selectPredictionFilterList = (state: RootState) => state.models.predictionExplorerState.selectedFilters;
export const selectIsRawModalVisible = (state: RootState) => state.models.predictionExplorerState.isRawModalVisible;
export const selectSelectedPrediction = (state: RootState) => state.models.predictionExplorerState.selectedPrediction;
export const selectSelectedDates = (state: RootState) => state.models.predictionExplorerState.selectedDates;
export const selectCurrentDatasource = (state: RootState) => state.models.predictionExplorerState.currentDataSource;
export const selectAllSelected = (state: RootState) => state.models.predictionExplorerState.allSelected;
export const selectIsEditModalVisible = (state: RootState) => state.models.common.isDrawerVisible;
export const selectModelUploadState = (state: RootState) => state.models;
