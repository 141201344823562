import { getMessages, RoutingHelper } from 'common';
import { FilterPanel } from 'common/components/DashboardView/components';
import { ProjectActiveView } from 'common/helpers/RoutingHelper/entities';
import { GFallback, GTabs } from 'components';
import React, { lazy, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ProjectFiltersFactory } from './entities';
import { useProjectFilter } from './hooks';
import { updateProjectFilters } from './store';
import Styles from './Styles.module.scss';

const ProjectDashboard = lazy(() => import(/* webpackChunkName: 'ProjectDashboard_View' */ './views/Dashboard/index'));
const ProjectCreate = lazy(() => import(/* webpackChunkName: 'ProjectCreate_View' */ './views/ProjectCreate/index'));

export default function Project() {
    const { activeView } = useParams();
    const filterPanelProps = useProjectFilter();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!activeView) RoutingHelper.Project({ activeView: 'project-dashboard' }).navigate();
        if (activeView === 'project-dashboard' || activeView === 'project-archived')
            dispatch(updateProjectFilters(ProjectFiltersFactory()));
    }, [activeView]);

    return (
        <>
            <FilterPanel {...filterPanelProps} />
            <GTabs
                activeKey={activeView}
                onChange={setActiveKey}
                tabList={tabList}
                id="project-tabs"
                className={Styles.ProjectContainer}
            />
        </>
    );
}

export const setActiveKey = (key: ProjectActiveView) =>
    RoutingHelper.Project({ activeView: key }).navigate({ preventScrollReset: true });

const tabList = [
    {
        title: getMessages('001236'),
        key: 'project-dashboard',
        content: (
            <React.Suspense fallback={<GFallback />}>
                <ProjectDashboard />
            </React.Suspense>
        ),
    },
    {
        title: getMessages('000833'),
        key: 'project-create',
        content: (
            <React.Suspense fallback={<GFallback />}>
                <ProjectCreate />
            </React.Suspense>
        ),
    },
    {
        title: getMessages('001802'),
        key: 'project-archived',
        content: (
            <React.Suspense fallback={<GFallback />}>
                <ProjectDashboard />
            </React.Suspense>
        ),
    },
];
