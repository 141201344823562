import localforage from 'localforage';
import { useEffect, useRef, useState } from 'react';
import { useUpdate } from 'react-use';

export class DBStorageHelper<T = any> {
    storage: LocalForage;
    channel: BroadcastChannel;

    onUpdate: (key: string, value: T) => void;
    onRemove: (key: string) => void;

    constructor({ name, version, description, storeName }: LocalForageOptions) {
        this.storage = localforage.createInstance({
            name,
            version,
            description: description ?? name,
            storeName,
        });

        this.channel = new BroadcastChannel(name);

        this.channel.addEventListener('message', this.subscribe);
    }

    destroy = () => {
        this.channel.removeEventListener('message', this.subscribe);
        this.channel.close();
    };

    setItem = async (key: string, value: T) => {
        try {
            await this.storage.setItem(key, value);
            this.channel.postMessage({ action: 'update', key, value });
        } catch (error) {
            console.error(error);
        }
    };

    getItem = async (key: string): Promise<T> => {
        try {
            return await this.storage.getItem(key);
        } catch (error) {
            console.error(error);
        }
    };

    removeItem = async (key: string) => {
        try {
            await this.storage.removeItem(key);
            this.channel.postMessage({ action: 'remove', key });
        } catch (error) {
            console.error(error);
        }
    };

    subscribe = (evt: MessageEvent<EventContent<T>>) => {
        switch (evt.data.action) {
            case 'update':
                this.onUpdate?.(evt.data.key, evt.data.value);
                break;
            case 'remove':
                this.onRemove?.(evt.data.key);
                break;

            default:
                break;
        }
    };
}

export function useDBStorage<T = any>(options: LocalForageOptions, itemKey?: string) {
    const storageHelper = useRef(new DBStorageHelper<T>(options));
    const [state, setState] = useState<T>();
    const update = useUpdate();

    useEffect(() => {
        storageHelper.current.onUpdate = onChange;
        storageHelper.current.onRemove = onChange;

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            storageHelper.current.destroy();
        };
    }, []);

    useEffect(() => {
        if (itemKey) {
            storageHelper.current.getItem(itemKey).then(setState);
        }
    }, [itemKey]);

    async function onChange() {
        update();
        if (itemKey) {
            const newValue = await storageHelper.current.getItem(itemKey);

            setState(newValue);
        }
    }

    return [storageHelper.current, state] as const;
}

interface EventContent<T = any> {
    action: 'update' | 'remove';
    key: string;
    value?: T;
}

export * from './storageList';

