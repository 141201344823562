import { _entries, isNullOrUndefined } from 'common';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import { IProject } from '../entities';
import { useGetProjectListQuery } from './ProjectService';

export const selectDatasetFilters = (state: RootState) => state.project.newProjectState.datasetFilters;
export const selectModelFilters = (state: RootState) => state.project.newProjectState.modelFilters;
export const selectProjectFilter = (state: RootState) => state.project.commonState.projectFilters;
export const selectProjectSorting = (state: RootState) => state.project.commonState.projectSorting;
export const selectActiveProjectId = (state: RootState) => state.project.activeProjectId;
export const selectProjectList = (state: RootState) => state.project.projectList;
export const selectAnnotationFilters = (state: RootState) => state.project.newProjectState.annotationFilters;

export function useGetFilteredProjectList() {
    const projectFilters = useSelector(selectProjectFilter);
    const { data = [] } = useGetProjectListQuery(undefined);
    const sorting = useSelector(selectProjectSorting);
    const { activeView } = useParams();

    return useMemo(() => {
        let projectList =
            activeView === 'project-archived' ? data?.filter(item => item?.isArchived) : data?.filter(item => !item?.isArchived);

        const searchOptions = projectList?.map((item, index) => ({
            value: item.projectId,
            title: item.name,
            label: item.name,
        }));

        const fuse = new Fuse(searchOptions, options);
        switch (sorting) {
            case 'dateLatest':
                sortingFunction(projectList, -1);
                break;

            case 'dateOldest':
            default:
                sortingFunction(projectList, 1);
                break;
        }

        return _entries(projectFilters).reduce((acc, [key, value]: any) => {
            if (isNullOrUndefined(value)) return acc;
            switch (key) {
                case 'search':
                    if (value !== '') {
                        const fuseResultIds = fuse?.search(value)?.map((i: any) => i.item.value);
                        return acc
                            .filter(d => fuseResultIds?.includes(d.projectId))
                            .sort(function (a: IProject, b: IProject) {
                                return fuseResultIds?.indexOf(a?.projectId) - fuseResultIds?.indexOf(b?.projectId);
                            });
                    } else return acc;

                default:
                    return acc;
            }
        }, projectList);
    }, [data, sorting, projectFilters, activeView]);
}

function sortingFunction(data: Array<IProject>, type: number) {
    data?.sort((a, b) => {
        const dateA = dayjs(a.createdAt).unix(),
            dateB = dayjs(b.createdAt).unix();
        if (!dayjs(a.createdAt).isValid()) return 1;
        if (!dayjs(b.createdAt).isValid()) return -1;
        return type * (+dateA - +dateB);
    });
}

const options = {
    includeScore: true,
    minMatchCharLength: 3,
    includeMatches: true,
    keys: ['problemType', 'title', 'description', 'tags', 'modality'],
};
