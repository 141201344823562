import { DatasetsFiltersFactory, IDatasetFilters, IModelFilters, ModelFiltersFactory, SortingKey } from 'common';
import { AnnotationsFiltersFactory, IAnnotationFilters } from 'common/hooks/useFilteredAnnotationList';
import { IProject } from './IProject';

export * from './IProject';

export interface IProjectState {
    newProjectState: INewProjectState;
    commonState: ICommonState;
    activeProjectId: string;
    projectList: IProject[];
}

export function ProjectStateFactory(data?: Partial<IProjectState>): IProjectState {
    return {
        newProjectState: data?.newProjectState ?? NewProjectStateFactory(),
        commonState: CommonStateFactory(data?.commonState),
        activeProjectId: data?.activeProjectId ?? null,
        projectList: data?.projectList ?? [],
    };
}

export function CommonStateFactory(data?: Partial<ICommonState>): ICommonState {
    return {
        projectFilters: ProjectFiltersFactory(data?.projectFilters),
        projectSorting: data?.projectSorting ?? 'dateLatest',
    };
}

export function ProjectFiltersFactory(data?: Partial<IProjectFilters>): IProjectFilters {
    return {
        search: data?.search ?? null,
        isPublic: data?.isPublic ?? false,
    };
}

export interface INewProjectState {
    datasetFilters: IDatasetFilters;
    modelFilters: IModelFilters;
    annotationFilters: IAnnotationFilters;
}

export interface ICommonState {
    projectFilters: IProjectFilters;
    projectSorting: SortingKey;
}
export interface IProjectFilters {
    isPublic: boolean;
    search: string;
}

export function NewProjectStateFactory(data?: Partial<INewProjectState>): INewProjectState {
    return {
        datasetFilters: DatasetsFiltersFactory(data?.datasetFilters),
        modelFilters: ModelFiltersFactory(data?.modelFilters),
        annotationFilters: AnnotationsFiltersFactory(data?.annotationFilters),
    };
}
