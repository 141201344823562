import { Image } from "antd";
import cs from 'classnames';
import { getMessages, RoutingHelper } from 'common';
import { useCoverImage } from 'common/hooks/useCoverImage';
import { Copy20, LogoutBoxLine16, SettingLine16 } from 'components/assets/icons';
import { clearSession } from 'pages/Authentication/hooks';
import { setIsClipboardOpen, setUserDropdownOpen } from 'pages/User/store';
import { selectIsUserDropdownOpen } from 'pages/User/store/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCurrentUser } from 'redux/selectors';
import Styles from './Styles.module.scss';

export default function useUserMenu() {
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const open = useSelector(selectIsUserDropdownOpen);

    function handleOpenDropdown() {
        dispatch(setUserDropdownOpen(!open));
    }

    const handleLogout = useCallback(() => {
        clearSession();
        dispatch(setUserDropdownOpen(false));
        window.location.reload();
    }, []);

    function handleCloseDropdown() {
        dispatch(setUserDropdownOpen(false));
    }

    function handleShowClipboard() {
        dispatch(setIsClipboardOpen(true));
        handleCloseDropdown();
    }

    const userMenuList = [
        {
            key: 'settings',
            render: (
                <Link
                    onClick={handleCloseDropdown}
                    to={RoutingHelper.User({ location: 'settings', activeView: 'account' }).uri}
                >
                    <div>{getMessages('000228')}</div>
                </Link>
            ),
            icon: <SettingLine16 />,
        },
        {
            key: 'showClipboard',
            render: (
                <div onClick={handleShowClipboard}>
                    <div>{getMessages('001568')}</div>
                </div>
            ),
            icon: <Copy20 />,
        },
        {
            key: 'logout',
            render: <div onClick={handleLogout}>{getMessages('000151')}</div>,
            icon: <LogoutBoxLine16 />,
        },
    ];
    const { image } = useCoverImage({ src: currentUser?.avatar });

    const renderUserDropdown = (menu: any) => (
        <div>
            <div className={Styles.UserInfo}>
                {currentUser?.avatar !== '' ? <Image src={image} /> : null}
                <div className={Styles.UserName}>
                    {currentUser?.displayName !== currentUser?.email &&
                    currentUser?.displayName !== null &&
                    currentUser?.displayName !== '' ? (
                        <div className={cs(Styles.DisplayName, 'pBody-medium white')}>{currentUser?.displayName}</div>
                    ) : null}
                    <div className={cs(Styles.Email, 'pBody-medium white')}>{currentUser?.email}</div>
                </div>
            </div>
            {menu}
        </div>
    );

    return { userMenuList, renderUserDropdown, open, handleOpenDropdown };
}