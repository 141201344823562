import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, IWindowLevel, _entries } from "common";
import undoable from 'redux-undo';
import { IAugmentationProperties, ICommonState, IImageControls, PlaygroundStateFactory } from "../entities";
import { IPlaygroundDatasetFilters } from "../entities/ICommonState";
import { IPredictionControls } from "../entities/IPredictionControls";
import { IPredictionResult } from '../entities/IPredictionResult';
import { IClassControl } from "../entities/ISinglePrediction";


export const PlaygroundSlice = createSlice({
  name: "playground",
  initialState: PlaygroundStateFactory(),
  reducers: {
    setAugmentationProperties(state, { payload }: PayloadAction<Partial<IAugmentationProperties>>) {
      Object.assign(state.augmentationProperties, payload);
    },

    setPredictionResult(state, { payload }: PayloadAction<IPredictionResult>) {
      state.predictionResult = payload;
    },
    setPredictionRawResult(state, { payload }: PayloadAction<any>) {
      state.predictionRawResult = payload;
    },
    setExplanationResult(state, { payload }: PayloadAction<string>) {
      state.common.explainResult = payload;
    },
    setExplainLoading(state, { payload }: PayloadAction<boolean>) {
      state.common.isExplainLoading = payload;
    },
    setImageControls(state, { payload }: PayloadAction<Partial<IImageControls>>) {
      Object.assign(state.predictionControls.imageControls, payload);
    },

    updatePredictionControls(state: any, { payload }: PayloadAction<Partial<IPredictionControls>>) {
      Object.assign(state.predictionControls, payload);
    },

    updateCommonState(state, { payload }: PayloadAction<Partial<ICommonState>>) {
      Object.assign(state.common, payload);
    },

    updateClassControls(state, { payload }: PayloadAction<Dictionary<Partial<IClassControl>>>) {
      _entries(payload).forEach(([classKey, classControl]) => {
        Object.assign(state.predictionControls.classControls[classKey], classControl);
      });
    },

    setPredictionLoading(state, { payload }: PayloadAction<boolean>) {
      state.predictionResult.isLoading = payload;
    },

    setPredictionEmpty(state, { payload }: PayloadAction<boolean>) {
      state.predictionResult.isEmpty = payload;
    },

    setWindowLevel(state, { payload }: PayloadAction<IWindowLevel>) {
      state.predictionControls.windowLevel = payload;
    },

    setInteractiveViewerShow(state, { payload }: PayloadAction<boolean>) {
      state.predictionControls.interactiveViewerState.isActive = payload;
    },

    setFilterDrawerVisible(state, { payload }: PayloadAction<boolean>) {
      state.common.isDrawerVisible = payload;
    },

    setDatasetFilterOption(state: any, { payload }: PayloadAction<Partial<IPlaygroundDatasetFilters>>) {
      Object.assign(state.common.datasetFilters, payload);
    },

    setImageSimilarityList(state, { payload }: PayloadAction<Dictionary<any>>) {
      state.common.imageSimilarityList = payload;
    },
  },
});

export const { setAugmentationProperties, setPredictionResult, setImageControls,
  updateClassControls, updatePredictionControls,
  setPredictionLoading, setPredictionEmpty, updateCommonState,
  setWindowLevel, setInteractiveViewerShow, setFilterDrawerVisible, setDatasetFilterOption,
  setExplanationResult, setImageSimilarityList,
  setExplainLoading,setPredictionRawResult
} = PlaygroundSlice.actions;

export default undoable(PlaygroundSlice.reducer);
