import { Dictionary, IWindowLevel } from 'common';
import { IsIncludeOrEqual } from 'common/Utils/IsIncludeOrEqual';
import { IEntityItem } from './Image';
import { IStudy } from './Study';

export interface IDataset {
    datasetId: string;
    datasetName: string;
    category: string[];
    channel: string;
    sourceId: string;
    sampleSize: number;
    locationURL: string;
    tags: string[];
    createdDate: string;
    lastUpdatedDate: string;
    modality: string[];
    version: string;
    sourceDevice: string[];
    shape: string;
    lastValidation: string;
    anatomy: string[];
    datasetAccessOptions: IAccessOptions;
    compatibleModelIds: string[];
    datasetPicture: string;
    datasetSummary: string;
    encoding: DatasetEncoding[];
    archived: boolean;
    compatibleAnnotations: string[];
    compatibleMetadatas: string[];
    defaultAnnotationId: string;
    defaultWindowLevel?: IWindowLevel;

    defaultMetadataId: string;
    patientLevel?: 'single' | 'multi';
    pathology: string[];
    procedure: string[];
    metadata?: Dictionary;
    entities?: Array<IEntityItem>;
    tracking: IImageTracking;
    storeLocation: string;
    viewCount: number;
    createdTimestamp: number;
    outlierImagesList: Array<IOutlierImages>;
    uniqueImagesList: Array<any>;
    datasetAnalysisStatus: number;
    lastActivity: string;
    studyList?: Array<IStudy>;
    datasetType?: 'study' | 'simple' | "genome" | any; //!important update this ASAP
    curated?: boolean;
    curated_dataset_list?: string[];
    datasetDetail?: string;
    download_options?: any;
    studyCount?: number;
    updated?: boolean;
    dataServer?: DataServer;
    pacsId?: string;
    tabular?: boolean;
}

export interface IDatasetResponsePayload {
    dataset_id: string;
    dataset_name: string;
    category: string[];
    channel: string;
    source_id: string;
    sample_size: number;
    location_url: string;
    tags: string[];
    created_timestamp: number;
    last_updated_timestamp: number;
    modality: string[];
    dataset_version: string;
    source_device: string[];
    shape: string;
    last_validation: number;
    anatomy: string[];
    dataset_access_options: IAccessOptions;
    compatible_model_ids: string[];
    dataset_image: string;
    dataset_summary: string;
    encoding: DatasetEncoding[];
    archived: boolean;
    compatible_annotation_ids: string[];
    compatible_metadata_ids: string[];
    default_annotation_id: string;
    default_metadata_id: string;
    dataset_picture: string;
    default_window_level?: IWindowLevel;
    patient_level?: 'single' | 'multi';
    pathology: string[];
    procedure: string[];
    metadata?: Dictionary;
    entities?: Array<IEntityItem>;
    tracking?: IImageTracking;
    store_location?: string;
    view_count?: number;
    outlier_image_list?: Array<IOutlierImages>;
    unique_images: [];
    dataset_analysis_status?: number;
    last_activity?: number;
    study_list?: Array<IStudy>;
    dataset_type?: 'study' | 'simple' | "genome";
    curated?: boolean;
    curated_dataset_list?: string[];
    dataset_detail?: string;
    download_options?: any;
    study_count?: number;
    updated?: boolean;
    data_server?: DataServer;
    pacs_id?: string;
    tabular?: boolean;
}

export interface IAccessOptions {
    access: 'public' | 'private';
    owner_email: string;
    permission_list: Array<IPermissionList>;
}

export interface IPermissionList {
    user: string;
    timestamp: number;
    type: string;
    allow_download?: boolean;
    allow_modify?: boolean;
}
export interface IImageTracking {
    num_images_stored?: number;
    num_images_failed?: number;
    total_images?: number;
}

export interface IOutlierImages {
    image_id: string;
    distance: number;
}

export type SeriesNumber = string | number;
export interface IDataSource {
    source_id: string;
    source_name: string;
    email: string;
    details: string;
    image: string;
    website: string;
}

export function isStudyDataset(dataset: IDataset) {
    return dataset?.datasetType === 'study' || dataset?.patientLevel === 'single';
}

export function isDicom(dataset: IDataset) {
    return IsIncludeOrEqual(dataset?.encoding, ['dicom', 'ima'], true);
}

export type DatasetEncoding = 'dicom' | 'ima' | 'nifti' | 'png' | 'jpg' | 'jpeg' | 'bmp' | 'tiff' | 'tif' | 'webp' | 'csv' | 'xlsx' | 'tsv';
export type VideoExtention = 'PAL' | 'NTSC' | 'XGA' | 'SXGA' | 'SECAM';
export type TabularExtention = 'csv' | 'xlsx' | 'tsv';

export type DataServer = 'pacs' | 'platform';

export * from './Image';
export * from './Study';
export * from './TransformDataset';

