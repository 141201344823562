import { IsIncludeOrEqual, _entries, _isEmpty, _uniqBy } from 'common/Utils';
import {
    getSearchOptionsWithHighlight,
    useHandleSearch,
} from 'common/components/DashboardView/components/FilterPanel/useHandleSearch';
import type { Dictionary, SortingKey } from 'common/entities';
import { ModelMode } from 'common/enum';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { IModel } from 'pages/Models/entities';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectArchivedModelList, selectModelList } from 'redux/selectors';

let fuseResult: Fuse.FuseResult<any>[];
let fuseResultIds: Array<string>;

const options = {
    includeScore: true,
    minMatchCharLength: 3,
    includeMatches: true,
    keys: ['problemType', 'title', 'description', 'tags', 'modality'],
};

export function useFilteredModelList<T extends IModelFilters>({
    filters,
    archived,
    sortBy,
    additionalFiltering,
    ...args
}: IUseFilteredModelListArgs<T>) {
    let coreList = useSelector(selectModelList);
    let modelList = coreList.filter(model => model.mode !== ModelMode.SmartAnnotation && model.mode !== ModelMode.Experimental);
    const archivedModelList = useSelector(selectArchivedModelList);
    const { activeView } = useParams();

    const searchOptions = (activeView === 'ArchivedModels' ? archivedModelList : modelList)?.map(item => ({
        value: item.modelId,
        title: item.modelName,
        label: <div key={item.modelId}>{item.modelName}</div>,
        description: item.modelSummary,
        tags: !!item?.tags?.length ? item.tags.join(', ') : '',
        modality: !!item?.modality?.length ? item.modality : '',
    }));
    const { handleSearch } = useHandleSearch(searchOptions, options);

    let result: Array<IModel> = args?.modelList || (archived ? structuredClone(archivedModelList) : structuredClone(modelList));

    switch (sortBy) {
        case 'popularityDesc':
            result.sort((a, b) => b.viewCount - a.viewCount);
            break;

        case 'popularityAsc':
            result.sort((a, b) => a.viewCount - b.viewCount);
            break;

        case 'dateLatest':
            result = sortingFunction(result, -1);
            break;

        case 'dateOldest':
        default:
            result = sortingFunction(result, 1);
            break;
    }

    _entries(filters).forEach(([key, value]) => {
        if (_isEmpty(value)) return;
        switch (key) {
            case 'search':
                fuseResult = handleSearch(value);
                fuseResultIds = fuseResult?.map(i => {
                    return i.item?.value;
                });

                if (value !== '')
                    result = result
                        ?.filter(d => fuseResultIds?.includes(d.modelId))
                        .sort(function (a: IModel, b: IModel) {
                            return fuseResultIds.indexOf(a.modelId) - fuseResultIds.indexOf(b.modelId);
                        });
                else return result;

                break;

            case 'hidePreviousVersions':
                if (!value) return;

                result = _uniqBy(result, 'modelName')
                    .map(item => item.modelName)
                    .map(
                        item =>
                            result
                                .filter(d => d.modelName === item)
                                .sort((a, b) => dayjs(b.createdDate).unix() - dayjs(a.createdDate).unix())[0]
                    );
                break;

            case 'hidePublicModels':
                if (!value) return;
                result = result.filter(d => d?.modelAccessOptions?.access === 'private');
                break;

            case 'showDeployedModels':
            case 'showCompatibles':
                break;

            default:
                result = result.filter(d => IsIncludeOrEqual(d[key as keyof IModel], value as any, true));
        }

        result = additionalFiltering?.(result, key, value) ?? result;
    });

    // this sort func ensures that blank dates moved to bottom
    function sortingFunction(result: Array<IModel>, type: number) {
        return [...result].sort((a, b) => {
            const dateA = dayjs(a.createdDate).unix(),
                dateB = dayjs(b.createdDate).unix();
            if (!dayjs(a.createdDate).isValid()) return 1;
            if (!dayjs(b.createdDate).isValid()) return -1;
            return type * (+dateA - +dateB);
        });
    }

    const modelIds = result.map(item => item.modelId);

    const filteredFuseResult = fuseResult?.filter(d => modelIds?.includes(d.item.value));

    const searchOptionsWithHighlight = getSearchOptionsWithHighlight(filteredFuseResult);

    return {
        filteredModelList: result,
        searchOptionsWithHighlight,
        modelList,
    };
}

export interface IUseFilteredModelListArgs<T> {
    modelList?: Array<IModel>;
    filters: Partial<T> | Dictionary;
    sortBy?: SortingKey;
    archived?: boolean;
    additionalFiltering?: (list: Array<IModel>, key: keyof T, value: any) => Array<IModel>;
}

export interface IModelFilters {
    search: string;
    modality: string;
    anatomy: string;
    problemType: string;
    hidePreviousVersions: boolean;
    hidePublicModels: boolean;
    deploymentStatus: string;
    showDeployedModels: boolean;
    vendorId: string;
}

export function ModelFiltersFactory(data?: IModelFilters): IModelFilters {
    return {
        search: data?.search || null,
        modality: data?.modality || null,
        anatomy: data?.anatomy || null,
        problemType: data?.problemType || null,
        hidePreviousVersions: data?.hidePreviousVersions || true,
        hidePublicModels: data?.hidePublicModels || false,
        deploymentStatus: data?.deploymentStatus || null,
        showDeployedModels: data?.showDeployedModels || false,
        vendorId: data?.vendorId || null,
    };
}

export type CategoricalModelFilters = 'modality' | 'anatomy' | 'problemType' | 'vendorId' | 'deploymentStatus';
export type BooleanModelFilters = 'hidePreviousVersions' | 'hidePublicModels';
