import type { Image, Viewport } from 'cornerstone-core';
import { IMeasurementData, IPoint } from '.';

export interface CornerstoneMouseClickEvent extends CornerstoneMouseEvent {}

export interface CornerstoneMouseDragEvent extends CornerstoneMouseEvent {
    ctrlKey: boolean;
    metaKey: boolean;
    shiftKey: boolean;
}

export interface CornerstoneMouseEvent {
    buttons: number;
    currentPoints: IPoints;
    deltaPoints: IPoints;
    element: HTMLElement;
    image: Image;
    lastPoints: IPoints;
    startPoints: IPoints;
    type: string;
    viewport: Viewport;
    event: MouseEvent;
}

export interface InteractionEvent {
    toolData: IMeasurementData;
}

export interface IPoints {
    canvas: IPoint;
    client: IPoint;
    image: IPoint;
    page: IPoint;
}

export const CornerstoneToolEvents = {
    MOUSE_DOWN: 'cornerstonetoolsmousedown',
    MOUSE_UP: 'cornerstonetoolsmouseup',
    MOUSE_DOWN_ACTIVATE: 'cornerstonetoolsmousedownactivate',
    MOUSE_DRAG: 'cornerstonetoolsmousedrag',
    MOUSE_MOVE: 'cornerstonetoolsmousemove',
    MOUSE_CLICK: 'cornerstonetoolsmouseclick',
    MOUSE_DOUBLE_CLICK: 'cornerstonetoolsmousedoubleclick',
    MOUSE_WHEEL: 'cornerstonetoolsmousewheel',
    TOUCH_START: 'cornerstonetoolstouchstart',
    TOUCH_START_ACTIVE: 'cornerstonetoolstouchstartactive',
    TOUCH_END: 'cornerstonetoolstouchend',
    TOUCH_DRAG: 'cornerstonetoolstouchdrag',
    TOUCH_DRAG_END: 'cornerstonetoolstouchdragend',
    TOUCH_PINCH: 'cornerstonetoolstouchpinch',
    TOUCH_ROTATE: 'cornerstonetoolstouchrotate',
    TOUCH_PRESS: 'cornerstonetoolstouchpress',
    TAP: 'cornerstonetoolstap',
    DOUBLE_TAP: 'cornerstonetoolsdoubletap',
    MULTI_TOUCH_START: 'cornerstonetoolsmultitouchstart',
    MULTI_TOUCH_START_ACTIVE: 'cornerstonetoolsmultitouchstartactive',
    MULTI_TOUCH_DRAG: 'cornerstonetoolsmultitouchdrag',
    KEY_DOWN: 'cornerstonetoolskeydown',
    KEY_UP: 'cornerstonetoolskeyup',
    KEY_PRESS: 'cornerstonetoolskeypress',
    MEASUREMENT_ADDED: 'cornerstonetoolsmeasurementadded',
    MEASUREMENT_MODIFIED: 'cornerstonetoolsmeasurementmodified',
    MEASUREMENT_COMPLETED: 'cornerstonetoolsmeasurementcompleted',
    MEASUREMENT_REMOVED: 'cornerstonetoolsmeasurementremoved',
    TOOL_DEACTIVATED: 'cornerstonetoolstooldeactivated',
    CLIP_STOPPED: 'cornerstonetoolsclipstopped',
    STACK_SCROLL: 'cornerstonetoolsstackscroll',
    STACK_PREFETCH_IMAGE_LOADED: 'cornerstonetoolsstackprefetchimageloaded',
    STACK_PREFETCH_DONE: 'cornerstonetoolsstackprefetchdone',
    LABELMAP_MODIFIED: 'cornersontetoolslabelmapmodified',

    // Custom events
    INTERACTION_STARTED: 'cornerstonetoolsinteractionstarted',
    INTERACTION_UPDATED: 'cornerstonetoolsinteractionupdated',
    INTERACTION_ENDED: 'cornerstonetoolsinteractionended',
} as const;
