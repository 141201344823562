

export interface IUsageStatistics {
    loading: boolean;
    transactionList: ITransaction[];
}


export function UsageStatisticsFactory(data?: Partial<IUsageStatistics>): IUsageStatistics {
    return {
        loading: data?.loading || false,
        transactionList: data?.transactionList || [],
    };
}

export interface ITransaction {
    response: IPredictionResponse;
    time_taken: number;
    deployment_id: string;
    transaction_id: string;
    image_id: string;
    model_id: string;
    dataset_id: string;
    time_stamp: string;
    user_id: string;
}

export interface IPredictionResponse {
    prediction_class: number;
    confidence: number;
    logits: number[];
    status: number;
}