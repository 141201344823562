import dayjs from 'dayjs';
import { IDataset, IDatasetResponsePayload } from '.';

export function TransformDataset(data?: Partial<IDatasetResponsePayload>): IDataset {
    if (!data) return null;
    return {
        datasetId: data.dataset_id,
        datasetName: data.dataset_name,
        category: data.category,
        channel: data.channel,
        sourceId: data.source_id,
        sampleSize: data.sample_size,
        locationURL: data.location_url,
        tags: data.tags,
        createdDate: data.created_timestamp ? dayjs.unix(data.created_timestamp).format('MM.DD.YYYY') : '',
        lastUpdatedDate: data.last_updated_timestamp ? dayjs.unix(data.last_updated_timestamp).format('MM.DD.YYYY') : '',
        modality: data.modality,
        version: data.dataset_version,
        sourceDevice: data.source_device,
        shape: data.shape,
        lastValidation: data.last_validation ? dayjs.unix(data.last_validation).format('MM.DD.YYYY') : '',
        anatomy: data.anatomy,
        datasetAccessOptions: data.dataset_access_options,
        compatibleModelIds: data.compatible_model_ids,
        datasetPicture: data.dataset_picture,
        datasetSummary: data.dataset_summary,
        encoding: data.encoding,
        archived: data.archived,
        compatibleAnnotations: data.compatible_annotation_ids,
        compatibleMetadatas: data.compatible_metadata_ids,
        defaultAnnotationId: data.default_annotation_id,
        defaultWindowLevel: data.default_window_level ?? { ww: null, wc: null },
        defaultMetadataId: data.default_metadata_id,
        pathology: data.pathology,
        procedure: data.procedure,
        metadata: data.metadata ?? {},
        entities: data.entities ?? [],
        tracking: data.tracking,
        storeLocation: data.store_location,
        viewCount: data.view_count,
        createdTimestamp: data.created_timestamp,
        outlierImagesList: data.outlier_image_list ?? [],
        uniqueImagesList: data.unique_images ?? [],
        datasetAnalysisStatus: data.dataset_analysis_status,
        lastActivity: data.last_activity ? dayjs.unix(data.last_activity).format('MM.DD.YYYY') : '',
        studyList: data.study_list ?? [],
        datasetType: data.dataset_type,
        curated: data.curated,
        curated_dataset_list: data.curated_dataset_list,
        datasetDetail: data.dataset_detail,
        download_options: data.download_options,
        studyCount: data.study_count,
        updated: data.updated,
        dataServer: data.data_server,
        pacsId: data.pacs_id,
        tabular: data.tabular,
    };
}
