import { ServiceEnum } from 'common/enum';
import { GetMethod, PostMethod } from './Client';

const APIService = {
    Validation: {
        PlatformSummary: new GetMethod('Validation/PlatformSummary'),
        GetValidationList: new GetMethod('Validation/GetValidationList'),
        DeleteValidationRun: new PostMethod('Validation/ValidationSummary/DeleteValidationRun'),
        GetStaticPayload: new GetMethod('Validation/GetStaticPayload'),
        ArchiveValidation: new PostMethod('Validation/ArchiveValidation'),
        GetTransactionlist: new GetMethod('Validation/GetTransactionlist'),
        GetBlindSpotAnalysisResult: new PostMethod('Validation/GetBlindSpotAnalysisResult'),
        GetExternalValidation: new PostMethod('Validation/GetExternalValidation'),
        UpdateNameAndAccessOptions: new PostMethod('Validation/UpdateNameAndAccessOptions'),
        GetValidationMetadataList: new GetMethod('Validation/GetValidationMetadataList'),
        GetBlindSpotAnalysisTrackingList: new GetMethod('Validation/GetBlindSpotAnalysisTrackingList'),
        RemoveBlindSpotAnalysis: new PostMethod('Validation/RemoveBlindSpotAnalysis'),
        GetMismatchedPredictions: new PostMethod('Validation/GetMismatchedPredictions'),
        GetIntersectionValidationList: new PostMethod('Validation/GetIntersectionValidationList'),
        UnArchiveValidation: new PostMethod('Validation/UnArchiveValidation'),
        DeleteValidationRunsByModelId: new PostMethod('Validation/DeleteValidationRunsByModelId'),
        ExportPredictions: new PostMethod('Validation/ExportPredictions'),
        ExportFile: new PostMethod('Validation/ExportFile'),
        GetFile: new GetMethod('Validation/GetFile'),
        GetValidationItem: new GetMethod('Validation/GetValidationItem'),
        GetImageValidationResults: new GetMethod('Validation/GetImageValidationResults'),
    },
    Models: {
        GetModelList: new GetMethod('Models/GetModelList'),
        GetDeploymentTracking: new GetMethod('Models/GetDeploymentTracking'),
        GetVendors: new GetMethod('Models/GetVendors'),
        AddNewVendor: new PostMethod('Models/AddNewVendor'),
        AddModelImage: new PostMethod('Models/AddModelImage'),
        UpdateArchitectureList: new PostMethod('Models/UpdateArchitectureList'),
        ArchiveModel: new PostMethod('Models/ArchiveModel'),
        UnArchiveModel: new PostMethod('Models/UnArchiveModel'),
        GetInferenceSpeed: new GetMethod('Models/GetInferenceSpeed'),
        GetDeploymentLogs: new GetMethod('Models/GetDeploymentLogs'),
        GetDeploymentUtilization: new GetMethod('Models/GetDeploymentUtilization'),
        AddModelActivity: new PostMethod('Models/AddModelActivity'),
        GetModelActivity: new GetMethod('Models/GetModelActivity'),
        EditModel: new PostMethod('Models/EditModel'),
        UpdatePreEmptiveOptions: new PostMethod('Models/UpdatePreEmptiveOptions'),
        ChangeModelDownloadPermission: new PostMethod('Models/ChangeModelDownloadPermission'),
        ChangeAccessType: new PostMethod('Models/ChangeAccessType'),
        RemoveUserPermission: new PostMethod('Models/RemoveUserPermission'),
        ChangeModelSourceFileModifyPermission: new PostMethod('Models/ChangeModelSourceFileModifyPermission'),
    },
    Datasets: {
        GetDatasetList: new GetMethod('Datasets/GetDataSetList'),
        GetImageDetails: new GetMethod('Datasets/GetImageDetails'),
        AddDatasetImage: new PostMethod('Datasets/AddDatasetImage'),
        GetSources: new GetMethod('Datasets/GetSources'),
        AddNewSource: new PostMethod('Datasets/AddNewSource'),
        AddSourceImage: new PostMethod('Datasets/AddSourceImage'),
        ArchiveDataset: new PostMethod('Datasets/ArchiveDataset'),
        UnArchiveDataset: new PostMethod('Datasets/UnArchiveDataset'),
        ChangeAccessType: new PostMethod('Datasets/ChangeAccessType'),
        RemoveUserPermission: new PostMethod('Datasets/RemoveUserPermission'),
        AddDatasetActivity: new PostMethod('Datasets/AddDatasetActivity'),
        GetDatasetActivity: new GetMethod('Datasets/GetDatasetActivity'),
        GetDatasetImages: new PostMethod('Datasets/GetDatasetImages'),
        EditDateset: new PostMethod('Datasets/EditDataset'),
        ChangeDatasetDownloadPermission: new PostMethod('Datasets/ChangeDatasetDownloadPermission'),
        CreateCuratedDataset: new PostMethod('Datasets/CreateCuratedDataset'),
        GetDatasetSimilarities: new GetMethod('Datasets/GetDatasetSimilarities'),
        GetImageStatistics: new GetMethod('Datasets/GetImageStatistics'),
        GetNoisyImageList: new GetMethod('Datasets/GetNoisyImageList'),
        GetAnnotationStats: new GetMethod('Datasets/GetAnnotationStats'),
        GetMetadataStats: new GetMethod('Datasets/GetMetadataStats'),
        GetDatasetCoverImage: new GetMethod('Datasets/GetDatasetCoverImage'),
        GetImageIndex: new GetMethod('Datasets/GetImageIndex'),
    },
    Annotation: {
        CreateAnnotation: new PostMethod('Annotation/CreateAnnotation'),
        UpdateProject: new PostMethod('Annotation/UpdateAnnotationTracking'),
        RemoveAnnotation: new PostMethod('Annotation/RemoveAnnotation'),
        EditAnnotation: new PostMethod('Annotation/EditAnnotation'),
        GetProjectStatus: new GetMethod('Annotation/GetProjectStatus'),
        ExportAnnotation: new GetMethod('Annotation/ExportAnnotation'),
    },
    Metadata: {
        GetImageMetadataList: new PostMethod('Metadata/GetImageMetadataList'),
        GetMetadataList: new GetMethod('Metadata/GetMetadataList'),
        GetMetadataFilterFields: new PostMethod('Metadata/GetMetadataFilterFields'),
        SetDefaultMetadata: new PostMethod('Metadata/SetDefaultMetadata'),
        CreateMetadata: new PostMethod('Metadata/CreateMetadata'),
        RemoveMetadata: new PostMethod('Metadata/RemoveMetadata'),
        EditMetadata: new PostMethod('Metadata/EditMetadata'),
        UpdateEntities: new PostMethod('Metadata/UpdateEntities'),
        GetFilteredImageCounts: new PostMethod('Metadata/GetFilteredImageCounts'),
    },
    Platform: {
        AddNewCredential: new PostMethod('Platform/AddNewCredential'),
        DeleteCredentialKey: new PostMethod('Platform/DeleteCredentialKey'),
        CloudCredentials: new GetMethod('Platform/CloudCredentials'),
        GetUserList: new GetMethod('Platform/GetUserList'),
        GetVerifiedUsers: new GetMethod('Platform/GetVerifiedUsers'),
        AddVerifiedUser: new GetMethod('Platform/AddVerifiedUser'),
        RemoveVerifiedUser: new GetMethod('Platform/RemoveVerifiedUser'),
        GetImageStreamLogs: new GetMethod('Platform/GetImageStreamLogs'),
        CheckServerInternetConnection: new GetMethod('Platform/CheckServerInternetConnection'),
        CreateNewUser: new PostMethod('Platform/CreateNewUser'),
    },
    Notifications: {
        GetUserNotifications: new GetMethod('Notifications/GetUserNotifications'),
        GetReportedErrorLogs: new GetMethod('Notifications/GetReportedErrorLogs'),
        UpdateNotification: new PostMethod('Notifications/UpdateNotification'),
        MarkAsReadNotifications: new PostMethod('Notifications/MarkAsReadNotifications'),
        MarkAsReportedNotification: new PostMethod('Notifications/MarkAsReportedNotification'),
        RemoveNotifications: new PostMethod('Notifications/RemoveNotifications'),
    },
    Project: {
        CreateProject: new PostMethod('Project/CreateProject'),
        GetProjectList: new GetMethod('Project/GetProjectList'),
        EditProject: new PostMethod('Project/EditProject'),
        DeleteProject: new PostMethod('Project/DeleteProject'),
        ChangeAccessType: new PostMethod('Project/ChangeAccessType'),
        RemoveUserPermission: new PostMethod('Project/RemoveUserPermission'),
    },
    Configurations: {
        UpdateConfigurations: new PostMethod('Configurations/UpdateConfigurations'),
    },
};

const AuthService = {
    GetUser: new GetMethod('GetUser', null, ServiceEnum.Auth),
    Register: new PostMethod('Register', null, ServiceEnum.Auth),
    Login: new PostMethod('Login', null, ServiceEnum.Auth),
    Delete: new PostMethod('Delete', null, ServiceEnum.Auth),
    CheckToken: new GetMethod('CheckToken', null, ServiceEnum.Auth),
    CheckActivationToken: new GetMethod('CheckActivationToken', null, ServiceEnum.Auth),
    SetActivationToken: new PostMethod('SetActivationToken', null, ServiceEnum.Auth),
    NewPassword: new PostMethod('NewPassword', null, ServiceEnum.Auth),
    ForgotPassword: new PostMethod('ForgotPassword', null, ServiceEnum.Auth),
    UpdateSession: new GetMethod('UpdateSession', null, ServiceEnum.Auth),
    GetMicrosoftOAauthURL: new GetMethod('oauth/GetMicrosoftOAauthURL', null, ServiceEnum.Auth),
    AddUserImage: new PostMethod('AddUserImage', null, ServiceEnum.Auth),
    RemoveUserImage: new PostMethod('RemoveUserImage', null, ServiceEnum.Auth),
    UpdateUser: new PostMethod('UpdateUser', null, ServiceEnum.Auth),
    SetNotificationPreferences: new PostMethod('SetNotificationPreferences', null, ServiceEnum.Auth),
    GetNotificationPreferences: new GetMethod('GetNotificationPreferences', null, ServiceEnum.Auth),
    GetValidationMetricsView: new GetMethod('GetValidationMetricsView', null, ServiceEnum.Auth),
    UpdateValidationMetricsView: new PostMethod('UpdateValidationMetricsView', null, ServiceEnum.Auth),
    ChangeLoginPassword: new GetMethod('ChangeLoginPassword', null, ServiceEnum.Auth),
    GetEnvConfiguration: new GetMethod('GetEnvConfiguration', null, ServiceEnum.Auth),
    GetUserOrganizationMembers: new GetMethod('GetUserOrganizationMembers', null, ServiceEnum.Auth),
    FavoriteValidation: new PostMethod('FavoriteValidation', null, ServiceEnum.Auth),
    AddFeedbackImage: new PostMethod('AddFeedbackImage', null, ServiceEnum.Auth),
    RemoveFeedbackImage: new PostMethod('RemoveFeedbackImage', null, ServiceEnum.Auth),
    ChangeAdminPassword: new PostMethod('ChangeAdminPassword', null, ServiceEnum.Auth),
};

const PlatformService = {
    Validation: {
        Plot: new PostMethod('plot', null, ServiceEnum.Validation),
        Generate: new PostMethod('generate', null, ServiceEnum.Validation),
        BlindSpotAnalysis: new PostMethod('blind_spot_analysis', null, ServiceEnum.Validation),
        BlindSpotCombinations: new PostMethod('blind_spot_combinations', null, ServiceEnum.Validation),
        GetBlandAtmanData: new PostMethod('emm/metric', null, ServiceEnum.Validation),
    },
    Prediction: {
        StartBatch: new PostMethod('batch/prediction', null, ServiceEnum.Prediction),
        StartStudyBatch: new PostMethod('study/prediction', null, ServiceEnum.Prediction),
        GetStudyResult: new PostMethod('/study/result', null, ServiceEnum.Prediction),
        SmartAnnotationPredict: new PostMethod('smart_annotation/prediction/', null, ServiceEnum.Prediction),
    },
    Manage: {
        UploadImage: new PostMethod('image/store/playground', null, ServiceEnum.Management),
        GetImage: new GetMethod('image/get_image_stream', null, ServiceEnum.Management),
        getImageDicomDetails: new GetMethod('image/get_image_meta', null, ServiceEnum.Management),
        UploadDataSetFile: new PostMethod('dataset/upload', null, ServiceEnum.Management),
        DownloadMetaDataTemplate: new GetMethod('dataset/metadata/template', null, ServiceEnum.Management),
        DatasetDbAdd: new PostMethod('dataset/add', null, ServiceEnum.Management),
        DatasetDelete: new PostMethod('dataset/delete', null, ServiceEnum.Management),
        DownloadMetaData: new GetMethod('dataset/get_images_meta', null, ServiceEnum.Management),
        GetEntities: new PostMethod('metadata/get_entities', null, ServiceEnum.Management),
        GetModelArtifact: new PostMethod('model/template/zip', null, ServiceEnum.Management),
        DeleteModelArtifact: new PostMethod('model/upload/delete', null, ServiceEnum.Management),
        UploadModel: new PostMethod('model/upload/zip', null, ServiceEnum.Management),
        CreateModel: new PostMethod('model/db/add', null, ServiceEnum.Management),
        UploadModelFile: new PostMethod('model/upload/file', null, ServiceEnum.Management),
        ExportCocoAnnotation: new PostMethod('annotation/to_coco', null, ServiceEnum.Management),
        ExportYoloAnnotation: new PostMethod('annotation/to_yolo', null, ServiceEnum.Management),
        UploadCocoAnnotation: new PostMethod('annotation/coco_to_platform', null, ServiceEnum.Management),
        UploadYoloAnnotation: new PostMethod('annotation/yolo_to_platform', null, ServiceEnum.Management),
        UploadCSVAnnotation: new PostMethod('annotation/csv_to_platform', null, ServiceEnum.Management),
        DownloadDataset: new PostMethod('dataset/download', null, ServiceEnum.Management),
        ExportBatchPrediction: new PostMethod('prediction/export', null, ServiceEnum.Management),
        ExportAuditLogs: new PostMethod('logging/export_query', null, ServiceEnum.Management),
        DatasetExploreGetFile: new PostMethod('dataset/explore/get_file', null, ServiceEnum.Management),
        DatasetExplore: new PostMethod('dataset/explore', null, ServiceEnum.Management),
        ExportMaskAnnotation: new PostMethod('annotation/export_mask', null, ServiceEnum.Management),
    },
    Deployment: {
        GetLiveServices: new GetMethod('platform/live_services', null, ServiceEnum.Deployment),
        GetSystemUtilization: new GetMethod('platform/system_utilization', null, ServiceEnum.Deployment),
        DeployModel: new PostMethod(`models/deploy`, null, ServiceEnum.Deployment),
        StopAllModels: new GetMethod('models/stop/all', null, ServiceEnum.Deployment),
        StopModels: new PostMethod('models/stop/ids', null, ServiceEnum.Deployment),
        ExportAuditLogs: new PostMethod('logging/export_query', null, ServiceEnum.Deployment),
    },
    MLUtility: {
        GetSimilarImages: new PostMethod(`dataset_analysis/find_similar_images`, null, ServiceEnum.MLUtility),
        FindSimiliarDatasets: new GetMethod('similarity/find_similar_datasets/', null, ServiceEnum.MLUtility),
        BackgroundRemoval: new PostMethod('annotation/background_removal/', null, ServiceEnum.MLUtility),
        PixelClassificationTrain: new PostMethod('pixel_classification/train/', null, ServiceEnum.MLUtility),
        PixelClassificationPredict: new PostMethod('pixel_classification/predict/', null, ServiceEnum.MLUtility),
        GetSAMEmbedding: new PostMethod('/autosegmentation/', null, ServiceEnum.MLUtility),
        TrainModel: new PostMethod('training/tensorflow/train', null, ServiceEnum.MLUtility),
        SetModelMode: new PostMethod('training/set_model_mode', null, ServiceEnum.MLUtility),
    },
};

export { APIService, AuthService, PlatformService };

