import { DatasetsFiltersFactory, IDataset, IDatasetFilters, IDataSource, SortingKey } from 'common';
import { DatasetAnalysisStateFactory, IDatasetAnalysisState } from '../views/DatasetAnalysis/entities';
import { DatasetCurationStateFactory, IDatasetCurationState } from '../views/DatasetCuration/entities';
import { AnnotationManagementStateFactory, IAnnotationManagementState } from '../views/DatasetDetails/views/Annotation/entities';
import { IMetadataManagementState, MetadataManagementStateFactory } from '../views/DatasetDetails/views/Metadata/entities';
import { IUploadDatasetState, UploadDatasetStateFactory } from '../views/DatasetsUpload/entities';
import { IPacsExplorerState, PacsExplorerInitialState } from '../views/PacsExplorer/entities';

export interface IDatasetState {
    datasetList: Array<IDataset>;
    archivedDatasetList: Array<IDataset>;
    common: IDatasetCommonState;
    sourceList: Array<IDataSource>;
    datasetUploadState: IUploadDatasetState;
    metadataManagement: IMetadataManagementState;
    annotationManagement: IAnnotationManagementState;
    datasetCurationState: IDatasetCurationState;
    datasetAnalysisState: IDatasetAnalysisState;
    pacsExplorerState: IPacsExplorerState;
}

export function DatasetStateFactory(data?: IDatasetState): IDatasetState {
    return {
        datasetList: data?.datasetList || [],
        archivedDatasetList: data?.archivedDatasetList || [],
        common: DatasetCommonStateFactory(data?.common),
        sourceList: data?.sourceList || [],
        datasetUploadState: UploadDatasetStateFactory(data?.datasetUploadState),
        metadataManagement: MetadataManagementStateFactory(data?.metadataManagement),
        annotationManagement: AnnotationManagementStateFactory(data?.annotationManagement),
        datasetCurationState: DatasetCurationStateFactory(data?.datasetCurationState),
        datasetAnalysisState: DatasetAnalysisStateFactory(data?.datasetAnalysisState),
        pacsExplorerState: PacsExplorerInitialState,
    };
}

export interface IDatasetCommonState {
    datasetFilters: IDatasetFilters;
    isDrawerVisible: boolean;
    datasetSorting: SortingKey;
    datasetListLoading: boolean;
    isUpdateDatasetModalVisible: boolean;
    isClipboardModalVisible: boolean;
}

export enum FormatType {
    DICOM = 'dicom',
    NIFTI = 'nifti',
    JPG = 'jpg',
    PNG = 'png',
    TIFF = 'tiff',
    MHD = 'mhd',
    IMA = 'ima',
    DICOM_NO_EXT = 'dicom_no_ext',
}
export enum VideoExtention {
    PAL = 'PAL',
    NTSC = 'NTSC',
    XGA = 'XGA',
    SXGA = 'SXGA',
    SECAM = 'SECAM',
}

export enum TabularType {
    CSV = 'csv',
    TSV = 'tsv',
    XLSX = 'xlsx',
}

export enum DatasetAnalysisStatus {
    failed = 0,
    success = 1,
    inprogress = -1,
}

export function DatasetCommonStateFactory(data?: Partial<IDatasetCommonState>): IDatasetCommonState {
    return {
        datasetFilters: DatasetsFiltersFactory(data?.datasetFilters),
        isDrawerVisible: data?.isDrawerVisible ?? false,
        datasetSorting: data?.datasetSorting || 'dateLatest',
        datasetListLoading: data?.datasetListLoading || false,
        isUpdateDatasetModalVisible: data?.isUpdateDatasetModalVisible || false,
        isClipboardModalVisible: data?.isClipboardModalVisible || false,
    };
}

export * from './IMetadata';
