import { ImageViewerHelper } from 'common/helpers/ImageViewerHelper';
import { useImageViewer, useInitImageViewer } from 'common/helpers/ImageViewerHelper/hooks';
import { useEffect } from 'react';
import Styles from './Styles.module.scss';
import { useOnWheel } from 'pages/Playground/views/PredictionImage/hooks/useOnWheel';

export function GImageViewer({ image }: IGImageEditorProps) {
    const imageViewer = useImageViewer();
    const initImageViewer = useInitImageViewer();

    const onWheel = useOnWheel();

    useEffect(() => {
        initImageViewer();

        return () => {
            imageViewer?.destroy();
        };
    }, []);

    useEffect(() => {
        imageViewer?.loadImage(image);
    }, [image, imageViewer]);

    return (
        <div className={Styles.ImageViewerContainer} onWheel={onWheel}>
            <div
                className={Styles.ImageViewer}
                id={ImageViewerHelper.viewerId}
            />
        </div>
    );
}

interface IGImageEditorProps {
    image?: string;
}
