import { isNullOrUndefined } from 'common/Utils';
import { PlatformService } from 'common/services';
import _debounce from 'lodash/debounce';
import { DBStorageHelper, storageList } from '../DBStorageHelper';
import { StorageList, StorageType } from '../StorageHelper/StorageList';
import { EmbeddingResponseType, EmbeddingStatus } from './entities';

export class EmbeddingAPI {
    store = new DBStorageHelper<string>(storageList.EmbeddingDB);
    statusMap = StorageList[StorageType.EmbeddingStatus];
    downloadProgressMap = StorageList[StorageType.EmbeddingDownloadProgress];
    currentEmbedding: string | undefined;
    currentEmbeddingImageId: string | undefined;
    datasetId: string | undefined;

    debouncedGetEmbedding = _debounce(this.getEmbedding, 500);
    async getEmbedding({ dataset_id, image_id }: EmbeddingParams) {
        if (!image_id || (!dataset_id && !this.datasetId)) return;
        if (dataset_id) this.datasetId = dataset_id;
        try {
            if (this.currentEmbeddingImageId === image_id) return this.currentEmbedding;
            this.currentEmbedding = await this.store.getItem(image_id);
            if (this.currentEmbedding) {
                this.downloadProgressMap.set(image_id, 100);
                this.currentEmbeddingImageId = image_id;
                this.statusMap.set(image_id, EmbeddingStatus.Loaded);
                return this.currentEmbedding;
            }
            const lastStatus = this.statusMap.get(image_id);
            if (lastStatus === EmbeddingStatus.Loaded || lastStatus === EmbeddingStatus.Loading) return;
            this.statusMap.set(image_id, EmbeddingStatus.Loading);

            const response: EmbeddingResponseType = await PlatformService.MLUtility.GetSAMEmbedding.post(undefined, {
                params: { image_id, dataset_id },
                onDownloadProgress: progressEvent => {
                    const totalLength = progressEvent.total;
                    if (isNullOrUndefined(totalLength)) return;
                    const progress = Math.round((progressEvent.loaded / totalLength) * 100);
                    this.downloadProgressMap.set(image_id, progress);
                },
            });

            this.downloadProgressMap.set(image_id, 100);
            this.currentEmbedding = response.data?.embeddings;
            this.currentEmbeddingImageId = image_id;
            this.statusMap.set(image_id, EmbeddingStatus.Loaded);
            this.store.setItem(image_id, response.data?.embeddings);
            return this.currentEmbedding;
        } catch (error) {
            console.error(error);
            this.statusMap.set(image_id, EmbeddingStatus.Failed);
        }
    }

    getParams(imageUrl: string): EmbeddingParams {
        const _url = new URL(imageUrl);
        const image_id = _url.searchParams.get('image_id');
        const dataset_id = _url.searchParams.get('dataset_id');
        return {
            image_id,
            dataset_id,
        };
    }
}

export interface EmbeddingParams {
    image_id: string;
    dataset_id?: string;
}
