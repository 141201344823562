import { TableColumnsType } from 'antd';
import { ColumnFilterItem, FilterDropdownProps, FilterSearchType } from 'antd/lib/table/interface';
import { _compact, _get, _keys, _uniq, isNullOrUndefined } from 'common/Utils';
import { IStudy } from 'common/entities';
import { getMessages } from 'common/helpers';
import dayjs from 'dayjs';
import { IColumnFilterItem } from 'pages/Validation/components/ValidationRunTable/helpers/interface';
import Styles from "./Styles.module.scss"

export default function useStudyTable(studyList: Array<IStudy>) {
    const metadataColumns: Array<string> = _uniq(studyList?.flatMap((item: IStudy) => _keys(item.metadata)));

    const metadataColumnList: TableColumnsType<IStudy> = metadataColumns?.map(metadata => {
        return {
            dataIndex: metadata,
            key: metadata,
            width: 200,
            title: metadata,
            render: (value, record) => record.metadata?.[metadata],
            ...getFilters('metadata', studyList, metadata),
            className: Styles.MetadataColumn,
        };
    });

    const columns: TableColumnsType<IStudy> = [
        {
            dataIndex: 'alias',
            key: 'alias',
            title: getMessages('000081'),
            width: 200,
            ...getFilters('alias', studyList),
        },
        {
            dataIndex: 'description',
            key: 'description',
            title: getMessages('000398'),
            width: 200,
            ...getFilters('description', studyList),
        },
        {
            dataIndex: 'series_count',
            key: 'series_count',
            width: 200,
            title: getMessages('001333'),
            ...getFilters('series_count', studyList),
        },
        {
            dataIndex: 'count',
            key: 'count',
            width: 200,
            title: getMessages('001328'),
            ...getFilters('count', studyList),
        },
        {
            dataIndex: 'date',
            key: 'date',
            width: 200,
            title: getMessages('000024'),
            render: (value, record) => dayjs.unix(value)?.format('MM/DD/YYYY'),
            sorter: (a: IStudy, b: IStudy) => sort(a.date?.toString(), b.date?.toString()),
        },

        ...metadataColumnList,
    ];

    const sort = (a: string, b: string) => a?.toLocaleLowerCase?.()?.localeCompare?.(b?.toLocaleLowerCase?.());

    return {
        tableColumns: columns,
    };
}

export function getFilters(dataPath: IDataPath, dataSource: IStudy[], dataValue?: string): IFilterProps {
    let filterList;
    if (dataPath === 'metadata') {
        filterList = dataSource?.map(record => _get(record, `${dataPath}.${dataValue}`)).flat();
    } else {
        filterList = dataSource?.map(record => _get(record, dataPath)).flat();
    }
    let keys;
    let filters;

    switch (dataPath) {
        case 'assigned':
            keys = [
                {
                    value: 'assigned',
                    name: 'Assigned',
                },
                {
                    value: 'notAssigned',
                    name: 'Not Assigned',
                },
            ];
            filters = keys.map((item: any) => ({
                value: item.value,
                text: <span className="filter-text">{item.name}</span>,
            }));
            break;
        default:
            filters = _compact(_uniq(filterList)).map(value => ({
                value,
                text: <span className="filter-text">{value}</span>,
            }));
            break;
    }

    return {
        filterSearch: (input, record) => {
            return record?.value?.toLocaleLowerCase?.()?.includes?.(input?.toLocaleLowerCase?.());
        },
        filters,
        onFilter: (value: string, record: IStudy) => {
            let cellValue = _get(record, dataPath);

            if (dataPath === 'assigned') {
                if (value === 'assigned') return cellValue;
                return !cellValue;
            }
            if (dataPath === 'metadata') {
                cellValue = _get(record, `${dataPath}.${dataValue}`);
            }

            if (!isNullOrUndefined(cellValue)) {
                if (Array.isArray(cellValue)) {
                    cellValue = cellValue.join(' ');
                }
                if (typeof cellValue === 'number') {
                    return cellValue === (value as any);
                }
                return cellValue?.toLocaleLowerCase?.()?.includes?.(value?.toLocaleLowerCase?.());
            }
            return false;
        },
    };
}

export interface IFilterProps {
    filterSearch?: FilterSearchType<IColumnFilterItem>;
    filters: Array<ColumnFilterItem>;
    onFilter: (value: string, record: IStudy) => boolean;
    filterDropdown?: React.ReactNode | ((props: FilterDropdownProps) => React.ReactNode);
}

export type IDataPath = keyof IStudy | Array<keyof IStudy> | 'assigned';