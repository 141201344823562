import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

const segmentationUtils = cornerstoneTools.importInternal('util/segmentationUtils');
const BaseBrushTool = cornerstoneTools.importInternal('base/BaseBrushTool');
const drawBrushPixels = segmentationUtils.drawBrushPixels;
const getCircle = segmentationUtils.getCircle;
const segmentationModule = cornerstoneTools.getModule('segmentation');

export default class GBrushTool extends BaseBrushTool {
    constructor(props: any = {}, context: any) {
        const defaultProps = {
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: { storeHistory: true, ...props.configuration },
        };

        super(props, defaultProps);
        this.props = props;

        this.touchDragCallback = this._paint.bind(this);
    }

    get lock() {
        return cornerstoneTools.getModule('globalConfiguration').configuration.lockAnnotationTools;
    }

    renderBrush(evt: any) {
        const { configuration, state } = segmentationModule;
        const eventData = evt.detail;
        const viewport = eventData.viewport;

        let mousePosition;

        if (this._drawing) {
            mousePosition = this._lastImageCoords;
        } else if (this._mouseUpRender) {
            mousePosition = this._lastImageCoords;
            this._mouseUpRender = false;
        } else {
            mousePosition = cornerstoneTools.store.state.mousePositionImage;
        }

        if (!mousePosition) {
            return;
        }

        const { rows, columns } = eventData.image;
        const { x, y } = mousePosition;

        if (x < 0 || x > columns || y < 0 || y > rows) return;

        const radius = configuration.radius;
        const context = eventData.canvasContext;

        const element = eventData.element;

        const stackState = cornerstoneTools.getToolState(element, 'stack');
        if (!stackState) {
            console.error('Consumers must define stacks in their application if using segmentations in cornerstoneTools.');
            return;
        }

        const stackData = stackState.data[0];
        const firstImageId = stackData.imageIds[0];

        const brushStackState = state.series[firstImageId];

        let colors;

        if (brushStackState) {
            const activeLabelmapIndex = brushStackState.activeLabelmapIndex;
            const labelmap3D = brushStackState.labelmaps3D[activeLabelmapIndex];

            const activeSegmentIndex = labelmap3D.activeSegmentIndex;

            colors = state.colorLutTables[labelmap3D.colorLUTIndex][activeSegmentIndex];
        } else {
            // No data yet, make brush the default color of colormap 0.
            colors = state.colorLutTables[0][1];
        }

        context.setTransform(1, 0, 0, 1, 0, 0);

        const circleRadius = radius * viewport.scale;
        const mouseCoordsCanvas = cornerstone.pixelToCanvas(element, mousePosition);
        context.beginPath();
        context.strokeStyle = `rgba(${colors?.[0]}, ${colors?.[1]}, ${colors?.[2]}, ${
            configuration?.[this.props.name]?.opacity ?? 1
        } )`;
        if (configuration?.[this.props.name]?.filled) {
            context.fillStyle = `rgba(${colors?.[0]}, ${colors?.[1]}, ${colors?.[2]}, ${
                configuration?.[this.props.name]?.opacity ?? 1
            } )`;
        }
        context.ellipse(mouseCoordsCanvas.x, mouseCoordsCanvas.y, circleRadius, circleRadius, 0, 0, 2 * Math.PI);
        context.lineWidth = configuration?.[this.props.name]?.lineWidth ?? 5;
        configuration?.[this.props.name]?.dashed && context.setLineDash([2, 2]);
        configuration?.[this.props.name]?.filled && context.fill();
        context.stroke();
    }

    _paint(evt: any) {
        if (this.lock) return;

        const { configuration } = segmentationModule;
        const eventData = evt.detail;
        const { rows, columns } = eventData.image;
        const { x, y } = eventData.currentPoints.image;

        if (x < 0 || x > columns || y < 0 || y > rows) {
            return;
        }

        const radius = configuration.radius;
        const pointerArray = getCircle(radius, rows, columns, x, y);

        const { labelmap2D, labelmap3D, shouldErase } = this.paintEventData;

        // Draw / Erase the active color.
        drawBrushPixels(pointerArray, labelmap2D.pixelData, labelmap3D.activeSegmentIndex, columns, shouldErase);

        cornerstone.updateImage(evt.detail.element);
    }
}
