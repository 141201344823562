import { Badge } from "antd";
import React from "react";
import Styles from "./Styles.module.scss";

export default function GBadge({ count, children }: GBadgeProps) {
  return (
    <Badge count={count > 9 ? "+9" : count} className={Styles.badgeMain}>
      {children}
    </Badge>
  );
}

interface GBadgeProps {
  count?: number;
  children?: React.ReactNode;
}
