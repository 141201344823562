/* eslint-disable eqeqeq */
import { DeploymentStatus, IWindowLevel, _toNumber, findWindowLevel } from "common";
import { IModel } from "pages/Models/entities";
import { StateWithHistory } from "redux-undo";
import { selectModelList } from "redux/selectors";
import { RootState } from "redux/store";
import { createSelector } from "reselect";
import { IPlaygroundState } from "../entities";


export const selectPlaygroundState = (state: RootState) => state.playground;
export const selectSelectedSlice = (state: RootState) => state.playground.present.common.selectedSlice;
export const selectIsUploadLoading = (state: RootState) => state.playground.present.common.isUploadLoading;
export const selectDatasetFilters = (state: RootState) => state.playground.present.common.datasetFilters;
export const selectIsDrawerVisible = (state: RootState) => state.playground.present.common.isDrawerVisible;
export const selectSimilarityList = (state: RootState) => state.playground.present.common?.imageSimilarityList;
export const selectExplainResult = (state: RootState) => state.playground.present.common.explainResult;
export const selectIsExplainLoading = (state: RootState) => state.playground.present.common.isExplainLoading;
export const selectIsRawModalVisible = (state: RootState) => state.playground.present.common.isRawModalVisible;
export const selectIsSimilarImagesModalVisible = (state: RootState) => state.playground.present.common.isSimilarImagesModalVisible;
export const selectIsClosedPredictionEditor = (state: RootState) => state.playground.present.common.isClosedPredictionEditor;
export const selectIsVisibleImageGallery = (state: RootState) => state.playground.present.common.isVisibleImageGallery;
export const selectIsInputFocused = (state: RootState) => state.playground.present.common.isInputFocused;
export const selectIsStudyModalOpen = (state: RootState) => state.playground.present.common.isStudyModalOpen;

export const selectPredictionResult = (state: RootState) => state.playground.present.predictionResult;
export const selectPredictionRawResult = (state: RootState) => state.playground.present.predictionRawResult;
export const selectPredictionResultIsEmpty = (state: RootState) => state.playground.present.predictionResult.isEmpty;

export const selectShowResultPicture = (state: RootState) => state.playground.present.predictionControls.showResultPicture;
export const selectClassControls = (state: RootState) => state.playground.present.predictionControls.classControls;
export const selectDicomMetaData = (state: RootState) => state.playground.present.predictionControls.dicomImageDetails?.metadata;
export const selectDicomRawMetaData = (state: RootState) => state.playground.present.predictionControls.dicomImageDetails?.metadata_raw;
export const selectDicomDetailsLoading = (state: RootState) => state.playground.present.predictionControls.dicomImageDetails?.loading;
export const selectPredictionControls = (state: RootState) => state.playground.present.predictionControls;
export const selectImageControls = (state: RootState) => state.playground.present.predictionControls.imageControls;
export const selectShowDicomViewer = (state: RootState) => state.playground.present.predictionControls.interactiveViewerState?.isActive;
export const selectUseRawImage = (state: RootState) => state.playground.present.predictionControls.imageControls.useRawImage;
export const selectRawWindowLevel = (state: RootState) => state.playground.present.predictionControls.windowLevel;
export const selectShowGroundTruth = (state: RootState) => state.playground.present.predictionControls.showGroundTruth;
export const selectDownloadProgress = (state: RootState) => state.playground.present.predictionControls.interactiveViewerState.downloadProgress;
export const selectExplainParams = (state: RootState) => state.playground.present.predictionControls.isExplainParams;
export const selectIsBrushToolBoxVisible = (state: RootState) =>
    state.playground.present.predictionControls.isBrushToolBoxVisible;


export const selectAugmentationProperties = (state: RootState) => state.playground.present.augmentationProperties;

export const selectDeployedModelList = createSelector<any, Array<IModel>>(
    selectModelList,
    (models: Array<IModel>) => models.filter(model => model.deploymentStatus === DeploymentStatus.UP)
);

export const transformWindowLevel = (windowLevel: IWindowLevel) => ({
    ww: _toNumber(windowLevel?.ww),
    wc: _toNumber(windowLevel?.wc),
});

export const selectWindowLevel = createSelector<any, IWindowLevel>(
    selectRawWindowLevel,
    transformWindowLevel
);

export const selectWindowLevelValue = createSelector<any, string>(
    selectWindowLevel,
    (windowLevel: IWindowLevel) => findWindowLevel(windowLevel) || undefined
);

export const selectPlaygroundPastLength = createSelector<any, number>(
    selectPlaygroundState,
    (playgroundState: StateWithHistory<IPlaygroundState>) => playgroundState.past.length
);

export const selectPlaygroundFutureLength = createSelector<any, number>(
    selectPlaygroundState,
    (playgroundState: StateWithHistory<IPlaygroundState>) => playgroundState.future.length
);
