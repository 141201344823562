import { Image } from 'antd';
import cx from 'classnames';
import { IsIncludeOrEqual } from 'common/Utils';
import { getMessages } from 'common/helpers';
import { useCoverImage } from 'common/hooks/useCoverImage';
import GTooltip from 'components/GTooltip';
import { HasAnnotation } from 'components/assets/icons';
import ModelCardImage from 'components/assets/images/ModelCardImage.png';
import TagList from '../TagList';
import StatsTags, { IStats } from '../TagList/StatsTags';
import Styles from './Styles.module.scss';

export default function CardImage({
    imageSrc,
    datasetId,
    statsList,
    defaultAnnotationId,
    extraTagField,
    infoTagList,
    tagList,
}: IImageSectionProps) {
    const { image } = useCoverImage({ src: imageSrc, datasetId: datasetId });

    return (
        <div className={Styles.CardImage}>
            <div className={Styles.TagContainer}>
                {extraTagField ? extraTagField : <TagList tagList={infoTagList} />}
                {Boolean(tagList?.length) && (
                    <div className={Styles.Tags}>
                        {tagList
                            .filter(tag => tag && !IsIncludeOrEqual(tag, 'N/A'))
                            .map((tag, i) => (
                                <div
                                    className={cx(Styles.TagItem, 'caption2-bold truncate')}
                                    style={{ backgroundColor: tagPalette[i] }}
                                    key={i}
                                >
                                    {getValueFromField(tag)}
                                </div>
                            ))}
                    </div>
                )}
            </div>
            <Image
                preview={false}
                src={image}
                alt="image"
                fallback={ModelCardImage}
                placeholder={ModelCardImage}
            />

            {defaultAnnotationId && (
                <GTooltip title={getMessages('000482')}>
                    <div className={Styles.AnnotationIcon}>
                        <HasAnnotation />
                    </div>
                </GTooltip>
            )}
            <StatsTags statsList={statsList} />
        </div>
    );
}

const tagPalette = ['#24D9D9', '#A4BF00'];

export const getValueFromField = (field: string | number | Array<string>) => (Array.isArray(field) ? field.join(' ') : field);
export interface IImageSectionProps<T = any> {
    imageSrc: string;
    datasetId?: string;
    statsList?: Array<IStats>;
    item?: T;
    defaultAnnotationId?: string;
    infoTagList?: Dictionary<string | Array<string>>;
    extraTagField?: React.ReactNode;
    tagList?: Array<string | Array<string>>;
}
