import { AugmentationPropertiesFactory, IAugmentationProperties } from "./IAugmentation";
import { CommonStateFactory, ICommonState } from "./ICommonState";
import { IPredictionControls, PredictionControlsFactory } from "./IPredictionControls";
import { IPredictionResult, PredictionResultFactory, IRawResponse } from "./IPredictionResult";

export interface IPlaygroundState {
    augmentationProperties: IAugmentationProperties;
    predictionResult: IPredictionResult;
    predictionRawResult:IRawResponse;
    predictionControls: IPredictionControls;
    common: ICommonState;
}

export function PlaygroundStateFactory(data?: Partial<IPlaygroundState>): IPlaygroundState {
    return {
        augmentationProperties: AugmentationPropertiesFactory(data?.augmentationProperties),
        predictionResult: PredictionResultFactory(data?.predictionResult),
        predictionControls: PredictionControlsFactory(data?.predictionControls),
        predictionRawResult: data?.predictionRawResult ?? null,
        common: CommonStateFactory(data?.common),
    };
}

export interface IUploadImageResponsePayload {
    image_id: string;
    n_slices: number;
    status: string;
}

export * from "./IAugmentation";
export * from "./ICommonState";
export * from "./ImageActions";
export * from "./IPredictionControls";
export * from "./IPredictionResult";

